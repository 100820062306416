/* eslint-disable no-case-declarations */
/* eslint-disable jsx-a11y/label-has-for */
/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useState } from "react";
import { graphql } from "gatsby";
import Helmet from "react-helmet";
import Player from "../components/Player";
import SongsList from "../components/SongsList";
import Layout from "../layout";
import config from "../../data/SiteConfig";

export default function PageTemplate({ data, pageContext }) {
  const [contactFormStatus, setContactFormStatus] = useState("");
  const {
    airtable: {
      data: {
        PageTitle,
        Content: {
          childMarkdownRemark: { html }
        }
      }
    }
  } = data;
  const { links, slug } = pageContext;

  let fullPageContent = (
    <div className="postcontent" dangerouslySetInnerHTML={{ __html: html }} />
  );
  switch (slug) {
    case "songlist":
      fullPageContent = (
        <>
          {fullPageContent}
          <div className="clr" />
          <SongsList />
          <div className="clr" />
        </>
      );
      break;
    case "media":
      fullPageContent = (
        <>
          {fullPageContent}
          <Player />
          <div style={{ marginTop: "15px" }}>
            <iframe
              title="toronto-oh-gazebo-concert-promo"
              width="560"
              height="315"
              src="https://www.youtube.com/embed/7E8gxo-Acfw?rel=0"
              frameBorder="0"
              allow="accelerometer; encrypted-media; gyroscope;"
              allowFullScreen
            />
          </div>
          <div style={{ marginTop: "15px" }}>
            <iframe
              title="god-bless-usa"
              width="560"
              height="315"
              src="https://www.youtube.com/embed/-XQBRv0wnaA?rel=0"
              frameBorder="0"
              allow="accelerometer; encrypted-media; gyroscope;"
              allowFullScreen
            />
          </div>
          <div style={{ marginTop: "15px" }}>
            <iframe
              title="what-a-wonderful-world"
              width="560"
              height="315"
              src="https://www.youtube.com/embed/kmOi8vxgLfE?rel=0"
              frameBorder="0"
              allow="accelerometer; encrypted-media; gyroscope;"
              allowFullScreen
            />
          </div>
          <div style={{ marginTop: "15px" }}>
            <iframe
              title="so-long"
              width="560"
              height="315"
              src="https://www.youtube.com/embed/daRvjX2D3ow?rel=0"
              frameBorder="0"
              allow="accelerometer; encrypted-media; gyroscope;"
              allowFullScreen
            />
          </div>
        </>
      );
      break;
    case "contact":
      const submitForm = ev => {
        ev.preventDefault();
        const form = ev.target;
        const formData = new FormData(form);
        const xhr = new XMLHttpRequest();
        xhr.open(form.method, form.action);
        xhr.setRequestHeader("Accept", "application/json");
        xhr.onreadystatechange = () => {
          if (xhr.readyState !== XMLHttpRequest.DONE) return;
          if (xhr.status === 200) {
            form.reset();
            setContactFormStatus("SUCCESS");
          } else {
            setContactFormStatus("ERROR");
          }
        };
        xhr.send(formData);
      };
      fullPageContent = (
        <>
          <div className="lt" style={{ width: "35%" }}>
            {fullPageContent}
          </div>
          <div className="rt" style={{ width: "55%" }}>
            <form
              id="contactForm"
              method="post"
              action="https://formspree.io/xgewpepv"
              onSubmit={submitForm}
            >
              <ul>
                <li>
                  <label htmlFor="name">Name:</label>
                  <input id="name" className="text" type="text" name="name" />
                </li>
                <li>
                  <label htmlFor="_replyto">Email:</label>
                  <input
                    id="email"
                    className="text"
                    type="text"
                    name="_replyto"
                  />
                </li>
                <li className="special">
                  <input
                    type="text"
                    name="_gotcha"
                    style={{ display: "none" }}
                  />
                </li>
                <li>
                  <label htmlFor="message">Message:</label>
                  <textarea id="message" name="message" rows="5" />
                </li>
                <li className="submitbutton">
                  {contactFormStatus === "SUCCESS" ? (
                    <p>
                      Thanks for contacting us! We will get back to you very
                      soon!
                    </p>
                  ) : (
                    <input
                      className="sendBtn"
                      type="submit"
                      value="Send Your Message"
                    />
                  )}
                </li>
              </ul>
            </form>
            {contactFormStatus === "ERROR" && (
              <p style={{ textAlign: "center" }}>
                Ooops! There was an error sending the form.
              </p>
            )}
            <p style={{ textAlign: "center" }}>
              *Please note: All fields are required to send your message.
            </p>
          </div>
          <div className="clr" />
        </>
      );
      break;
    default:
      break;
  }

  return (
    <Layout navLinks={links}>
      <Helmet title={`${PageTitle} | ${config.siteTitle}`} />
      <div id="content">
        <div id="contentInner">
          <div id="contentWrapper" className={slug}>
            <h2>{PageTitle}</h2>
            <div className="divider" />
            {fullPageContent}
          </div>
        </div>
        <div className="contentInnerBottom" />
        <div className="contentBottom" />
      </div>
    </Layout>
  );
}

export const query = graphql`
  query($slug: String!) {
    airtable(data: { PageId: { eq: $slug } }) {
      data {
        PageId
        PageTitle
        Content {
          childMarkdownRemark {
            html
          }
        }
      }
    }
  }
`;
