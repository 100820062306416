import React from "react";
import ReactPlayer from "./ReactPlayer";

export default function Player() {
  const tracks = [
    {
      img:
        "https://icon-library.net/images/music-icon-transparent/music-icon-transparent-11.jpg",
      name: "My Way",
      desc: "Light Touch's rendition of 'My Way'",
      src: "/mp3/MyWay.mp3"
    },
    {
      img:
        "https://icon-library.net/images/music-icon-transparent/music-icon-transparent-11.jpg",
      name: "New York",
      desc: "Light Touch's rendition of 'New York'",
      src: "/mp3/NewYork.mp3"
    },
    {
      img:
        "https://icon-library.net/images/music-icon-transparent/music-icon-transparent-11.jpg",
      name: "Special Angel",
      desc: "Light Touch's rendition of 'Special Angel'",
      src: "/mp3/SpecialAngel.mp3"
    },
    {
      img:
        "https://icon-library.net/images/music-icon-transparent/music-icon-transparent-11.jpg",
      name: "Turn Around",
      desc: "Light Touch's rendition of 'Turn Around'",
      src: "/mp3/TurnAround.mp3"
    }
  ];

  return <ReactPlayer tracks={tracks} />;
}
