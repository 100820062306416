import React from "react";
import { graphql, useStaticQuery } from "gatsby";

export default function SongsList() {
  const fullSongsList = useStaticQuery(graphql`
    query SongsList {
      allAirtable(filter: { table: { eq: "Songs" } }) {
        edges {
          node {
            data {
              Title
              Genre {
                data {
                  id
                  GenreName
                }
              }
            }
          }
        }
      }
    }
  `);
  const {
    allAirtable: { edges: allSongs }
  } = fullSongsList;
  const songs = allSongs.map(({ node: { data: { Title, Genre } } }) => ({
    songTitle: Title,
    genre: Genre[0].data.GenreName
  }));
  const slowSongs = songs.filter(({ genre }) => genre === "Slow");
  const miscSongs = songs.filter(({ genre }) => genre === "Miscellaneous");
  const countrySongs = songs.filter(({ genre }) => genre === "Country");
  const shuffleSongs = songs.filter(({ genre }) => genre === "Shuffles");
  const fastSongs = songs.filter(({ genre }) => genre === "Fast");

  return (
    <>
      <div className="lt" style={{ width: "38%", fontSize: "1.25em" }}>
        <ul className="songList">
          <li className="head">Slow</li>
          {slowSongs.map(({ songTitle, genre }) => (
            <li key={`${genre}-${songTitle}`}>{songTitle}</li>
          ))}
        </ul>
      </div>
      <div className="lt" style={{ width: "30%", fontSize: "1.25em" }}>
        <ul className="songList">
          <li className="head">Miscellaneous</li>
          {miscSongs.map(({ songTitle, genre }) => (
            <li key={`${genre}-${songTitle}`}>{songTitle}</li>
          ))}
          <li className="head">Country</li>
          {countrySongs.map(({ songTitle, genre }) => (
            <li key={`${genre}-${songTitle}`}>{songTitle}</li>
          ))}
        </ul>
      </div>
      <div className="lt" style={{ width: "30%", fontSize: "1.25em" }}>
        <ul className="songList">
          <li className="head">Shuffles</li>
          {shuffleSongs.map(({ songTitle, genre }) => (
            <li key={`${genre}-${songTitle}`}>{songTitle}</li>
          ))}
          <li className="head">Fast</li>
          {fastSongs.map(({ songTitle, genre }) => (
            <li key={`${genre}-${songTitle}`}>{songTitle}</li>
          ))}
        </ul>
      </div>
    </>
  );
}
